/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/locomotive-scroll@4.1.4/dist/locomotive-scroll.min.js
 * - /npm/@barba/core@2.9.7/dist/barba.umd.min.js
 * - /npm/@barba/prefetch@2.1.10/dist/barba-prefetch.umd.min.js
 * - /npm/swiper@8.2.2/swiper-bundle.min.js
 * - /npm/gsap@3.10.4/dist/ScrollTrigger.min.js
 * - /npm/gsap@3.10.4/dist/gsap.min.js
 * - /npm/animejs@3.2.1/lib/anime.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
